import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-column flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex align-items-center text-gray-400 me-5 mb-2"
}
const _hoisted_8 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_11 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_12 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "nav-item" }
const _hoisted_18 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.formData.name), 1),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.formData.email)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: 'mailto:' + _ctx.formData.email,
                  class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                }, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/communication/com011.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formData.email), 1)
                ], 8, _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.formData.address)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen018.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formData.address), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.formData.website)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: _ctx.formData.website,
                  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2",
                  target: "_blank"
                }, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/coding/cod007.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formData.website), 1)
                ], 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("ul", _hoisted_12, [
            _createElementVNode("li", _hoisted_13, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/overview',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.overview")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_14, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/information',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.information")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_15, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/contacts',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.contacts")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_16, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/pim',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.pim")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_17, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/distribution',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.distribution")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_18, [
              _createVNode(_component_router_link, {
                to: '/merchants/' + _ctx.$route.params.id + '/fulfillment',
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("merchants.fulfillment")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}